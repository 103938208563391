<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs" />
            </v-col>
        </v-row>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="products products-subdivision"
                    ref="grid"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    :detail="detailTemplate"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <grid-toolbar class="justify-content-end">
                        <CButton
                            color="danger"
                            class="d-block"
                            :disabled="!groupDeletion.length"
                            @click="deleteEntities(groupDeletion)"
                        >
                            <i class="la la-trash"></i>
                            Удалить
                        </CButton>
                        <CButton
                            @click="
                                clearCurrentItem()
                                showEntityModal = true
                            "
                            class="d-block"
                            color="info"
                        >
                            <i class="la la-plus"></i>
                            Добавить товар
                        </CButton>
                    </grid-toolbar>
                    <template v-slot:selection_all>
                        <v-checkbox
                            :input-value="includeDeletionProducts.length && includeDeletionProducts.length === dataResult.data.length"
                            :value="includeDeletionProducts.length && includeDeletionProducts.length === dataResult.data.length"
                            class="mt-0 pt-0"
                            color="info"
                            hide-details
                            @change="selectAllProductsHandle"
                        />
                    </template>
                    <template v-slot:categories>
                        <dropdownlist
                            class="categories-filter"
                            :data-items="categoryFilter"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            @change="selectCategory"
                        />
                    </template>
                    <template v-slot:sections>
                        <dropdownlist
                            class="sections-filter"
                            :data-items="sectionFilter"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            @change="selectSection"
                        />
                    </template>
                    <template v-slot:agencies>
                        <dropdownlist
                            v-if="!isCurrentUserAgentUser"
                            class="agencies-filter"
                            :data-items="agencyFilter"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            @change="selectAgency"
                        />
                    </template>
                    <template
                        v-for="(filter, index) in structural_subdivision_boolean_columns"
                        v-slot:[`${filter.filterCell}`]
                    >
                        <boolean-filter :key="index" @change="selectFilter(filter.field, $event.value.value)" />
                    </template>
                    <template
                        v-for="(column, index) in structural_subdivision_boolean_columns"
                        v-slot:[`${column.cell}`]="{ props: { dataItem } }"
                    >
                        <td :key="index">
                            <div>
                                <span
                                  class="m-badge m-badge--wide m-badge--wide"
                                  :class="{
                                      'm-badge--success': dataItem[column.field],
                                      'm-badge--danger': !dataItem[column.field],
                                  }"
                                >
                                    {{ dataItem[column.field] ? 'ДА' : 'НЕТ' }}
                                </span>
                            </div>
                        </td>
                    </template>
                    <template v-slot:selection="{ props: { dataItem } }">
                        <td>
                            <v-simple-checkbox
                                :value="!!groupDeletion.find((deletionId) => dataItem.id === deletionId)"
                                class="d-flex justify-center"
                                color="info"
                                @input="selectProductHandle(dataItem.id)"
                            />
                        </td>
                    </template>
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <actions-dropdown :actions="getActions(dataItem)" />
                            </div>
                        </td>
                    </template>
                    <template v-slot:stock_balance="{ props: { dataItem } }" >
                        <td>
                            <v-text-field
                                :label="
                                    dataItem.stock_balance ||
                                    dataItem.stock_balance === 0
                                        ? ''
                                        : 'Введите количество'
                                "
                                type="number"
                                @change="changeBalance(dataItem.stock_balance, dataItem.id)"
                                hide-details="auto"
                                v-model="dataItem.stock_balance"
                                min="0"
                            />
                        </td>
                    </template>
                    <template v-slot:detail="{ props: { dataItem } }">
                        <div class="detail-wrapper">
                            <v-tabs v-model="tab">
                                <v-tab key="general-info" href="#general-info">
                                    Общая информация
                                </v-tab>
                                <v-tab key="images" href="#images">
                                    Изображение
                                </v-tab>
                                <v-tab key="posts" href="#posts">
                                    Файлы
                                </v-tab>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item
                                        key="general-info"
                                        id="general-info"
                                        md-label="Общая информация"
                                    >
                                        <table class="grey lighten-5 table table-striped m-table">
                                            <tbody>
                                                <tr
                                                    v-for="(detailField, index) in structural_subdivision_table_detail"
                                                    :key="index"
                                                >
                                                    <td width="200px">{{ detailField.label }}</td>
                                                    <td>
                                                        <p style="white-space: pre-line;">
                                                            {{ dataItem[detailField.field] }}
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="(column, index) in structural_subdivision_boolean_columns"
                                                    :key="index"
                                                >
                                                    <td>{{ column.title }}</td>
                                                    <td>
                                                        <span
                                                            class="m-badge m-badge--wide m-badge--wide"
                                                            :class="{
                                                                'm-badge--success':
                                                                    dataItem[column.field],
                                                                'm-badge--danger':
                                                                    !dataItem[column.field],
                                                            }"
                                                        >
                                                            {{ dataItem[column.field] ? 'ДА' : 'НЕТ' }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Размеры</td>
                                                    <td>
                                                        <p style="white-space: pre-line;">
                                                            {{
                                                                formatSizes(
                                                                    dataItem.sizes
                                                                )
                                                            }}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </v-tab-item>
                                    <v-tab-item
                                        key="images"
                                        id="images"
                                        md-label="Изображение"
                                    >
                                        <table
                                            class="grey lighten-5 table-responsive product-attachments__table"
                                        >
                                            <tbody>
                                                <tr
                                                    v-for="imgId in dataItem.images"
                                                    :key="imgId"
                                                    :id="imgId"
                                                >
                                                    <td class="text-primary">
                                                        <img
                                                            alt="изображение"
                                                            style="
                                                                padding: 5px;
                                                                max-height: 281px;
                                                                max-width: 221px;
                                                            "
                                                            :src="
                                                                `${base_url}/api/images/` +
                                                                imgId
                                                            "
                                                        />
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-if="
                                                        !dataItem.images ||
                                                        !dataItem.images.length
                                                    "
                                                >
                                                    <td class="text-primary">
                                                        Нет изображений
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </v-tab-item>
                                    <v-tab-item
                                        key="posts"
                                        id="posts"
                                        md-label="Файлы"
                                    >
                                        <table
                                            class="table-responsive product-attachments__table product-files__table"
                                        >
                                            <tbody>
                                                <tr
                                                    v-for="(file, key) in dataItem.files"
                                                    :id="file.id"
                                                    :key="key"
                                                >
                                                    <td class="text-primary">
                                                        <a
                                                            target="_blank"
                                                            :href="
                                                                `${base_url}/api/files/` + file.id
                                                            "
                                                        >
                                                          {{ file.file_name }}
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-if="
                                                        !dataItem.files ||
                                                        !dataItem.files.length
                                                    "
                                                >
                                                  <td class="text-primary">
                                                      Нет файлов
                                                  </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </div>
                    </template>
                </Grid>
            </intl>
        </localization>
        <v-row class="mt-3 mb-2">
            <v-col cols="12" class="pl-0 d-flex justify-end">
                <CButton
                    @click="downloadReport"
                    class="export-excel-btn"
                    color="success"
                >
                    <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
                </CButton>
            </v-col>
        </v-row>
        <product-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            :product-type="productType"
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        />
        <move-product-modal
            v-if="showMoveProductModal"
            :current-item="currentItem"
            :show-dialog="showMoveProductModal"
            :product-type="productType"
            :action="productAction"
            @click:outside="showMoveProductModal = false"
            @stored="
                showMoveProductModal = false
                updateTable()
            "
            @close="showMoveProductModal = false"
        />
    </div>
</template>

<script>
import TableMixin from '@/views/grid_elements/mixins/TableMixin'
import {Grid, GridToolbar} from '@progress/kendo-vue-grid'
import {
    IntlProvider,
    LocalizationProvider,
} from '@progress/kendo-vue-intl'
import { DropDownList } from '@progress/kendo-vue-dropdowns'
import { mapActions, mapGetters } from 'vuex'
import { Subdivision } from '@/config/product_types'
import ActionsDropdown from '@/views/grid_elements/ActionsDropdown'
import { showConfirm } from '@/lib/swal2'
import Breadcrumbs from '@/views/components/breadcrumbs'
import ProductCreateUpdateModal from '@/views/content/shared/modals/ProductCreateUpdateModal'
import MoveProductModal from '@/views/content/shared/modals/MoveProductModal'
import '@/views/grid_elements/translations'
import api from '@/api'
import { base_url } from '@/lib/axios'
import { toDataSourceRequestString } from '@progress/kendo-data-query'
import { showMessage } from '@/lib/toasted'
import BooleanFilter from '../../../components/BooleanFilter'
import downloadFile from "@/utils/download_file";
import {
  structural_subdivision_boolean_columns,
  structural_subdivision_table_columns,
  structural_subdivision_table_detail,
} from "@/views/content/shared/constants/structural_subdivision_products";

export default {
    name: 'products',
    components: {
        GridToolbar,
        Grid: Grid,
        dropdownlist: DropDownList,
        ActionsDropdown,
        BooleanFilter,
        breadcrumbs: Breadcrumbs,
        localization: LocalizationProvider,
        intl: IntlProvider,
        'product-modal': ProductCreateUpdateModal,
        'move-product-modal': MoveProductModal,
    },
    mixins: [TableMixin],
    async mounted() {
        await this.fetchCategoryListShort(this.productType)
        await this.fetchSectionListShort(this.productType)

        if (this.isCurrentUserAdministrator) {
            await this.fetchAgencyListShort()
        }

        await this.getData()
    },
    data() {
        return {
            base_url,
            productType: Subdivision,
            detailTemplate: 'detail',
            skip: 0,
            take: 10,
            pageSize: 10,
            gridPageable: { pageSizes: true },
            productQuantity: 0,
            total: 0,
            columns: structural_subdivision_table_columns,
            structural_subdivision_boolean_columns,
            structural_subdivision_table_detail,
            currentFile: null,
            tab: null,
            productAction: null,
            showMoveProductModal: false,
            groupDeletion: [],
        }
    },
    computed: {
        ...mapGetters('catalog', [
            'categoriesListShort',
            'sectionListShort',
        ]),
        ...mapGetters('agency', ['agenciesListShort']),
        includeDeletionProducts() {
            return this.dataResult.data.filter(
                (dataResult) => this.groupDeletion.includes(dataResult.id)
            )
        },
        categoryFilter() {
            return [
                {
                    id: null,
                    name: 'Все',
                },
                ...this.categoriesListShort,
            ]
        },
        sectionFilter() {
            return [
                {
                    id: null,
                    name: 'Все',
                },
                ...this.sectionListShort,
            ]
        },
        agencyFilter() {
            return [
                {
                    id: null,
                    name: 'Все',
                },
                ...this.agenciesListShort,
            ]
        },
    },
    methods: {
        ...mapActions('catalog', [
            'fetchCategoryListShort',
            'fetchSectionListShort',
        ]),
        ...mapActions('agency', ['fetchAgencyListShort']),
        fetchData: api.catalog.products,
        setCurrentFile(file) {
            this.currentFile = file
        },
        async downloadReport() {
            try {
                const response = await api.orders.getProductOrders(
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    })
                )

                downloadFile(`Отчет по товарам СП.xlsx`, response.data)
            } catch (e) {
                console.warn(e)
            }
        },
        clearCurrentFile() {
            this.currentFile = null
        },
        async changeBalance(count, id) {
            const data = new FormData()
            data.append('stock_balance', count)

            await api.catalog.updateProduct(id, data)
            await this.getData()
        },
        async togglePopular(item) {
            const { is_popular, id } = item
            const message = is_popular
                ? 'Вы действительно хотите сделать данный товар непопулярным?'
                : 'Вы действительно хотите сделать данный товар популярным?'
            const { isConfirmed } = await showConfirm(message)

            if (isConfirmed) {
                const {
                    data: { status, message },
                } = await api.catalog.toggleProductProperty(
                    id,
                    'is-popular'
                )
                showMessage(status, message)

                await this.getData()
            }
        },
        async toggleAvailability(item) {
            const { is_available, id } = item
            const message = is_available
                ? 'Вы действительно хотите сделать данный товар недоступным?'
                : 'Вы действительно хотите сделать данный товар доступным?'
            const { isConfirmed } = await showConfirm(message)

            if (isConfirmed) {
                const {
                    data: { status, message },
                } = await api.catalog.toggleProductProperty(
                    id,
                    'is-available'
                )
                showMessage(status, message)

                await this.getData()
            }
        },
        async toggleHidden(item) {
            const { is_hidden, id } = item
            const message = is_hidden
                ? 'Вы действительно хотите сделать данный товар видимым?'
                : 'Вы действительно хотите сделать данный товар скрытым?'
            const { isConfirmed } = await showConfirm(message)

            if (isConfirmed) {
                const {
                    data: { status, message },
                } = await api.catalog.toggleProductProperty(id, 'is-hidden')
                showMessage(status, message)

                await this.getData()
            }
        },
        async copyProduct(item) {
            this.setCurrentItem(item)
            this.productAction = 'copy'
            this.showMoveProductModal = true
        },
        async moveProduct(item) {
            this.setCurrentItem(item)
            this.productAction = 'move'
            this.showMoveProductModal = true
        },
        async deleteEntities(arrayOfDeletionProducts) {
            const { isConfirmed } = await showConfirm(
                'Вы действительно хотите удалить данный элемент?'
            )

            if (isConfirmed) {
                const {
                    data: { status, message },
                } = await api.catalog.deleteProduct(arrayOfDeletionProducts)
                if (this.groupDeletion.length === this.dataResult.data.length) {
                    this.updateTable(false, true)
                } else {
                  this.updateTable()
                }
                if (this.groupDeletion.length) {
                    this.groupDeletion = []
                }
                showMessage(status, message)
            }
        },
        selectProductHandle(productId) {
            const isExistsProductsId = this.groupDeletion.some(
                (deletionId) => deletionId === productId,
            )
            if (!isExistsProductsId) {
                this.groupDeletion.push(productId)
            } else {
                this.groupDeletion = this.groupDeletion.filter(
                    (deletionId) => deletionId !== productId
                )
            }
        },
        selectAllProductsHandle() {
            if (this.includeDeletionProducts.length === this.dataResult.data.length) {
                this.groupDeletion = this.groupDeletion.filter(
                    (deletionId) => !this.includeDeletionProducts.find(
                        (product) => product.id === deletionId
                    )
                )
            } else {
                this.groupDeletion = [
                    ...this.groupDeletion,
                    ...this.dataResult.data.map((product) => product.id)
                ]
            }
        },
        selectCategory({ value }) {
            if (value.id) {
                this.setFilter('category_id', 'eq', value.id)
            } else {
                this.removeFilter('category_id')
            }

            this.updateTable(true)
        },
        selectSection({ value }) {
            if (value.id) {
                this.setFilter('section_id', 'eq', value.id)
            } else {
                this.removeFilter('section_id')
            }

            this.updateTable(true)
        },
        prepareFetchParams() {
            return [
                this.productType,
                this.page,
                this.take,
                toDataSourceRequestString({
                    filter: this.filter,
                    sort: this.sort,
                }),
            ]
        },
        selectAgency({ value }) {
            if (value.id) {
                this.setFilter('agency_id', 'eq', value.id)
            } else {
                this.removeFilter('agency_id')
            }

            this.updateTable(true)
        },
        getActions(dataItem) {
            return [
                {
                    text:
                        'Сделать ' + (dataItem.is_popular ? 'не популярным' : 'популярным'),
                    icon:
                        'la ' + (dataItem.is_popular ? 'la-star-o' : 'la-star'),
                    handler: () => this.togglePopular(dataItem),
                },
                {
                    text:
                        'Сделать ' + (dataItem.is_available ? 'недоступным' : 'доступным'),
                    icon:
                        'la ' + (dataItem.is_available ? 'la-ban' : 'la-circle'),
                    handler: () => this.toggleAvailability(dataItem),
                },
                {
                    text: dataItem.is_hidden ? 'Сделать видимым' : 'Скрыть товар',
                    icon:
                        'la ' + (dataItem.is_hidden ? 'la-eye' : 'la-eye-slash'),
                    handler: () => this.toggleHidden(dataItem),
                },
                {
                    text: 'Копировать',
                    icon: 'la la-copy',
                    handler: () => this.copyProduct(dataItem),
                },
                {
                    text: 'Переместить',
                    icon: 'la la-arrow-right',
                    handler: () => this.moveProduct(dataItem),
                },
                {
                    text: 'Редактировать',
                    icon: 'la la-pencil',
                    handler: () => {
                      this.setCurrentItem(dataItem)
                      this.showEntityModal = true
                    },
                },
                {
                    text: 'Удалить',
                    icon: 'la la-trash',
                    handler: () => this.deleteEntities([dataItem.id]),
                },
            ]
        },
        formatSizes(sizes) {
            return sizes.map(size => size.name).join(', ')
        },
    },
}
</script>
