export const structural_subdivision_boolean_columns = [
    {
        field: 'is_hidden',
        title: 'Скрыта?',
        cell: 'hidden',
        type: 'boolean',
        filterCell: 'hidden_filter',
        filter: 'boolean',
        width: '100px',
    },
    {
        field: 'is_popular',
        title: 'Популярен?',
        cell: 'popular',
        type: 'boolean',
        filterCell: 'popular_filter',
        filter: 'boolean',
        width: '100px',
    },
    {
        field: 'is_available',
        title: 'Доступен?',
        cell: 'available',
        type: 'boolean',
        filterCell: 'available_filter',
        filter: 'boolean',
        width: '100px',
    },
]

export const structural_subdivision_table_detail = [
    {
        field: 'name',
        label: 'Название',
    },
    {
        field: 'price',
        label: 'Цена',
    },
    {
        field: 'short_description',
        label: 'Краткое описание',
    },
    {
        field: 'description',
        label: 'Описание',
    },
    {
        field: 'category_name',
        label: 'Категория',
    },
    {
        field: 'agency_name',
        label: 'Агенство',
    },
]

export const structural_subdivision_table_columns = [
    {
        field: '',
        title: '',
        cell: 'selection',
        sortable: false,
        width: '60px',
        filterCell: 'selection_all',
    },
    {
        field: 'id',
        title: 'Действия',
        cell: 'actions',
        filterable: false,
        sortable: false,
        width: '100px',
    },
    {
        field: 'name',
        title: 'Название',
    },
    {
        field: 'category_name',
        title: 'Категория',
        type: 'text',
        filter: 'text',
        filterCell: 'categories',
    },
    {
        field: 'section_name',
        title: 'Раздел',
        type: 'text',
        filter: 'text',
        filterCell: 'sections',
    },
    {
        field: 'agency_name',
        title: 'Агенство',
        type: 'text',
        filter: 'text',
        filterCell: 'agencies',
    },
    {
        field: 'product_quantity',
        title: 'Количество в пачке',
        filter: 'numeric',
        type: 'numeric',
    },
    ...structural_subdivision_boolean_columns,
    {
        field: 'stock_balance',
        title: 'Баланс на складе',
        cell: 'stock_balance',
        filter: 'numeric',
        type: 'numeric',
    },
    {
        field: 'stock_balance_updated_at',
        title: 'Дата изменения баланса',
        filter: 'date',
        format: '{0:dd.MM.yyyy hh:mm}',
        type: 'date',
    },
]
